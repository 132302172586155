import { Link } from 'react-router-dom';
import logo from 'assets/img/logo-radicalIT.png';
import React from 'react';

const HomeLink = (): JSX.Element => {
  return (
    <Link to='/'>
      <img src={logo} alt='RadicalIT logo' aria-describedby='Navigate to Radical IT home' />
    </Link>
  )
};

export default HomeLink;