import {useEffect, useState} from 'react';

export type Endpoint = (options: any) => Promise<any>;

const useEndpoint = <T extends any = any>(endpoint: Endpoint, options?: unknown): [T | undefined, any] => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState();

  useEffect(() => {
    if (endpoint) {
      let cancelled = false;

      (async () => {
        try {
          // Reset the data if we already have a result
          setData(undefined);
          const result = await endpoint(options);

          if (!cancelled) {
            setData(result);
          }
        } catch (e) {
          if (!cancelled) {
            setError(e);
          }
        }
      })();

      return (): void => {
        cancelled = true;
      };
    }
  }, [endpoint, options]);

  return [data, error];
};

export default useEndpoint;
