import EditBlogPostLink from './EditBlogPostLink';
import React from 'react';

const BlogEntryTitle = (props: { title: string, id: number, authenticated?: boolean }): JSX.Element => {
  return (
    <div className='row-c blog-entry_title'>
      <div className='col sm-12'>
        {props.title}
        {props.authenticated && <EditBlogPostLink id={props.id} />}
      </div>
    </div>
  );
};

export default BlogEntryTitle;