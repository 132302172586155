import {Fetch} from '_service/core';

const URL_LOGIN = 'login';
const URL_LOGOUT = 'logout';
const URL_PROFILE = 'userinfo';

function login(options: { data: FormData }): Promise<Response> {
  return Fetch(URL_LOGIN, { method: 'POST', body: options.data });
}

function logout(): Promise<Response> {
  return Fetch(URL_LOGOUT, { method: 'POST' });
}

function getProfile(): Promise<Response> {
  return Fetch(URL_PROFILE);
}

const Authentication = {
  login,
  logout,
  getProfile
};

export default Authentication;
