import Doc from 'assets/img/doc.svg'; // TODO: We need to replace this as it's off the interweb.
import {File} from '_sections/blog/@types';
import {isImage} from '_utils';
import {Link} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const FileThumb = (props: { file: File, onReplace: (file: File) => void, replacedAt?: number }): JSX.Element => {
  const file = props.file;
  const [rotation, setRotation] = useState<number>();
  const [tapeRotation, setTapeRotation] = useState<number>();
  const replaceFile = (): void => {
    props.onReplace(file);
  };

  useEffect(() => {
    setRotation(Math.floor(Math.random() * 14) - 7);
    setTapeRotation(Math.floor(Math.random() * 60) - 30);
  }, [file]);

  return (
    <div className={`polaroid-wrapper rot-${rotation}`}>
      <div className='polaroid'>
        <Link target={file.name} to={`/api/file/${file.name}?ts=${props.replacedAt}`}>
          {isImage(file.name) ?
            <img src={`/api/file/${file.name}?ts=${props.replacedAt}`} alt='thumbnail'/>
          :
            <img src={ Doc } alt='Document' />
          }
        </Link>
        <div className='polaroid_caption' title={file.name}>{file.name}</div>
      </div>
      <div className={`tape rot-${tapeRotation}`}>
        <Link className='tape_replace' to='#' onClick={replaceFile}>Replace</Link>
      </div>
    </div>
  );
};

export default FileThumb;