import {gql} from '@apollo/client';
import GraphQL, {Fetch} from '_service/core';

const URL_UPLOAD = 'file';
const FILES = 'content { name } totalElements';
const variables = GraphQL.firstPageVariables;
const fetchPolicy = GraphQL.fetchPolicy;

function getAllFiles(): Promise<any> {
  const dataKey = 'findAllFiles';
  return GraphQL.Query({
    query: gql`
      query ${dataKey}($page: Int!, $size: Int!) {
        ${dataKey}(page: $page, size: $size) {
          ${FILES}
        }
      }`,
    variables,
    fetchPolicy
  }, dataKey);
}

function upload(options: { data: FormData }): Promise<Response> {
  return Fetch(URL_UPLOAD, { method: 'POST', body: options.data });
}

function replace(options: { data: FormData }): Promise<Response> {
  return Fetch(URL_UPLOAD, { method: 'PUT', body: options.data });
}

const Files = {
  getAllFiles,
  replace,
  upload
};

export default Files;
