import {BlogEntryContent} from '_sections/blog/@types';
import {gql} from '@apollo/client';
import GraphQL from '_service/core';
import {wordCount} from '_utils';

const KEY_ALL_BLOG_ENTRIES = 'findAllBlogEntries';
const KEY_PUBLISHED_BLOG_ENTRIES = 'findPublishedBlogEntries';
const BLOG_ENTRY_FIELDS = 'id title thumbnail wordCount status ts';
const FULL_BLOG_ENTRY_FIELDS = `${BLOG_ENTRY_FIELDS} body`;
const BLOG_ENTRY = `content { ${BLOG_ENTRY_FIELDS} } totalElements`;
const PAGE_SIZE = 10;
const fetchPolicy = GraphQL.fetchPolicy;

/**
 * Locally-scoped method to get the appropriate sort of blog entries.
 * This will either be all of them (for admin) or just the published ones.
 * @param dataKey
 */
function findAllOrPublishedBlogEntries(dataKey: string, page = 0): Promise<any> {
  const variables = { page, size: PAGE_SIZE };
  return GraphQL.Query({
    query: gql`
      query ${dataKey}($page: Int!, $size: Int!) {
        ${dataKey}(page: $page, size: $size) {
          ${BLOG_ENTRY}
        }
      }`,
    variables,
    fetchPolicy
  }, dataKey);
}

/**
 * Exported functions to follow.
 */
function getAllBlogEntries(options: { page?: number }): Promise<any> {
  return findAllOrPublishedBlogEntries(KEY_ALL_BLOG_ENTRIES, options.page);
}

function getPublishedBlogEntries(options: { page?: number }): Promise<any> {
  return findAllOrPublishedBlogEntries(KEY_PUBLISHED_BLOG_ENTRIES, options.page);
}

function getBlogEntry(options: { id: number }): Promise<any> {
  if (isNaN(options.id)) {
    return Promise.resolve(undefined);
  }
  const dataKey = 'getBlogEntry';
  return GraphQL.Query({
    query: gql`
      query ${dataKey}($id: Int!) {
        ${dataKey}(id: $id) {
          ${FULL_BLOG_ENTRY_FIELDS}
        }
      }`,
    variables: { id: options.id }
  }, dataKey);
}

function createBlogEntry(input: BlogEntryContent): Promise<any> {
  input.wordCount = wordCount(input.body);
  const dataKey = 'createBlogEntry';
  return GraphQL.Mutate({
    mutation: gql`
      mutation ${dataKey}($input: BlogEntryInput!) {
        ${dataKey}(input: $input) {
          ${FULL_BLOG_ENTRY_FIELDS}
        }
      }`,
    variables: { input }
  }, dataKey);
}

function updateBlogEntry(id: number, input: BlogEntryContent): Promise<any> {
  input.wordCount = wordCount(input.body);
  const dataKey = 'modifyBlogEntry';
  return GraphQL.Mutate({
    mutation: gql`
      mutation ${dataKey}($id: Int!, $input: BlogEntryInput!) {
        ${dataKey}(id: $id, input: $input) {
          ${FULL_BLOG_ENTRY_FIELDS}
        }
      }`,
    variables: { id, input }
  }, dataKey);
}

const Blog = {
  createBlogEntry,
  getAllBlogEntries,
  getBlogEntry,
  getPublishedBlogEntries,
  updateBlogEntry
};

export default Blog;
