import classNames from 'classnames';
import {FormComponentProperties, FormComponentState} from '_components/@types';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';

const INPUT_TYPES = ['text', 'password'];
const TEXT_AREA = 'textarea';
const MARKDOWN = 'md';
const SELECT = 'select';

function isInputType(type: string): boolean {
  return INPUT_TYPES.indexOf(type) > -1;
}

function isTextArea(type: string): boolean {
  return type === TEXT_AREA;
}

function isMarkDown(type: string): boolean {
  return type === MARKDOWN;
}

function isSelect(type: string): boolean {
  return type === SELECT;
}

function setStateValidity(state: FormComponentState): void {
  if (state.options.required) {
    const value = state.value.trim();
    state.valid = value.length > 0;
    state.error = state.valid ? undefined : 'Required';
  } else {
    state.valid = true;
    state.error = undefined;
  }
}

const FormComponent = (props: FormComponentProperties): JSX.Element => {
  const options = props.options;
  const initialState: FormComponentState = {
    options, value: '', valid: !options.required, touched: false
  };
  const [state, setState] = useState(initialState);
  const [editingMD, setEditingMD] = useState(isMarkDown(options.type));

  // Handles a change to the value, also assessing its validity.
  function valueChange(value: string, trim = false): void {
    if (trim) {
      value = value.trim();
    }
    setState((prevState) => {
      const newState: FormComponentState = {
        ...prevState, value, touched: true
      };
      setStateValidity(newState);
      if (typeof options.onChange === 'function') {
        options.onChange(newState);
      }
      return newState;
    });
  }

  function hidePreview(): void {
    setEditingMD(true);
  }
  function showPreview(): void {
    setEditingMD(false);
  }

  useEffect(() => {
    if (props.setValue !== undefined) {
      valueChange(props.setValue);
    }
    // eslint-disable-next-line
  }, [props.setValue]);

  return (
    <div className={classNames('form_component', {
      invalid: !state.valid,
      touched: state.touched,
      'preview-markdown': !editingMD
    })}>
      <label htmlFor={options.id}>
        {options.label}
        {options.required && <span className='is-required'>*</span>}
      </label>
      {isInputType(options.type) &&
        <input type={options.type} id={options.id} name={options.id} value={state.value}
               placeholder={options.placeholder} onChange={e => valueChange(e.target.value)}
               onBlur={e => valueChange(e.target.value, true)} />
      }
      {isSelect(options.type) && options.options &&
        <select id={options.id} name={options.id} value={state.value}
                onChange={e => valueChange(e.target.value)} onBlur={e => valueChange(e.target.value)}>
          {Object.entries(options.options).map(([key, value]) =>
            <option value={key} key={key}>{value as string}</option>
          )}
        </select>
      }
      {(isTextArea(options.type) || isMarkDown(options.type)) &&
        <textarea id={options.id} name={options.id} value={state.value} rows={options.rows}
                  placeholder={options.placeholder} onChange={e => valueChange(e.target.value)}
                  onBlur={e => valueChange(e.target.value)} />
      }
      {isMarkDown(options.type) &&
        (editingMD ?
          <Link className='md-preview-toggle' to='#' onClick={showPreview}>Preview</Link>
        :
          <>
            <Link className='md-preview-toggle' to='#' onClick={hidePreview}>Edit</Link>
            <div className='markdown md-preview'>
              <ReactMarkdown source={state.value} escapeHtml={false} />
            </div>
          </>
        )
      }
      {state.error &&
        <span className='form_component-error'>{state.error}</span>
      }
    </div>
  );
};

export default FormComponent;
