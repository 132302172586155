import {
  ApolloClient,
  ApolloQueryResult,
  FetchPolicy,
  FetchResult,
  InMemoryCache,
  MutationOptions,
  QueryOptions
} from '@apollo/client';

const basePath = '/api';
const graphqlPath = `${basePath}/graphql`;
const fetchPolicy: FetchPolicy = 'network-only';
const firstPageVariables = { page: 0, size: 10 };
const apolloClient = new ApolloClient({
  uri: graphqlPath,
  cache: new InMemoryCache()
});

/**
 * Locally-scoped method to get the appropriate bit of data out of the
 * result returned by either the query or the mutation.
 * @param result
 * @param dataKey
 */
function extractPertinentData(result: any, dataKey: string): any | null {
  if (result.data && result.data.hasOwnProperty(dataKey)) {
    return result.data[dataKey];
  }
  return null;
}

async function Query(options: QueryOptions, dataKey: string): Promise<ApolloQueryResult<any>> {
  const result = await apolloClient.query(options);
  return extractPertinentData(result, dataKey) || result.data;
}

async function Mutate(options: MutationOptions, dataKey: string): Promise<FetchResult<any>> {
  const result = await apolloClient.mutate(options);
  return extractPertinentData(result, dataKey) || result;
}

export function Fetch(url: string, init?: RequestInit): Promise<any> {
  return fetch(`${basePath}/${url}`, init);
}

const GraphQL = {
  apolloClient,
  fetchPolicy,
  firstPageVariables,
  Query,
  Mutate
};

export default GraphQL;
