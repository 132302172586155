import {Admin} from 'admin';
import {ApiService, ServiceContextProvider} from '_service';
import {ApolloProvider} from '@apollo/client';
import 'assets/sass/app.scss';
import {AuthenticationContextProvider} from '_service';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Footer from 'footer';
import Header from 'header';
import {Home} from '_sections';
import React from 'react';
import {route} from '_utils';
import {Routes} from 'navigation';

const App = (): JSX.Element => {
  // Initiate the ApolloClient.
  const { apolloClient } = ApiService();

  return (
    <ApolloProvider client={apolloClient}>
      <ServiceContextProvider>
        <AuthenticationContextProvider>
          <Router>
            <div className='App'>
              <Header routes={ Routes } />
              <div className='container'>
                <Switch>
                  <Route exact path='/' component={ Home } />
                  {Routes.map(r => (
                    <Route path={ route(r) } component={ r.component } key={ r.route } />
                  ))}
                  <Route path='/admin' component={ Admin } />
                </Switch>
              </div>
              <Footer />
            </div>
          </Router>
        </AuthenticationContextProvider>
      </ServiceContextProvider>
    </ApolloProvider>
  );
};

export default App;
