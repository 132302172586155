import React from 'react';

const AboutUs = (): JSX.Element => {
  return (
    <div className='about-us'>
      About us content is likely to be static.
    </div>
  );
};

export default AboutUs;
