import {BlogEntryContent, BlogPosts, BlogPostsResponse} from '_sections/blog/@types';
import classNames from 'classnames';
import {navigate} from '_utils';
import React, {useEffect, useMemo, useState} from 'react';
import {useApi} from '_service';
import {useEndpoint} from '_hooks';
import {WhiteboardEntry} from '_sections/blog/whiteboard';

const Whiteboard = (props: { history: any }): JSX.Element | null => {
  const api = useApi();
  const options = useMemo(() => ({ blog: true }), []);
  const [getAllEntries] = useEndpoint<BlogPostsResponse>(api.getPublishedBlogEntries, options);
  const [entries, setEntries] = useState<BlogPosts>();
  const [selectedId, setSelectedId] = useState(NaN);

  useEffect(() => {
    if (getAllEntries) {
      setEntries(getAllEntries.content);
    }
  }, [getAllEntries, setEntries]);

  const onClickPostIt = (id: number) => {
    setSelectedId(id);
    navigate(props.history, `blog/${id}`, 1500);
  };

  if (!entries) {
    return null;
  }

  return (
    <div className={classNames('board', { 'selection-made': !isNaN(selectedId) })}>
      {entries.map((c: BlogEntryContent) => (
        <WhiteboardEntry content={c} key={c.id} onClick={onClickPostIt} selected={selectedId === c.id} />
      ))}
    </div>
  );
};

export default Whiteboard;
