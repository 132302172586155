import {AuthenticationService, useApi} from '_service';
import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthenticationContext = createContext({});

const AuthenticationContextProvider = (props: { children: any }): JSX.Element => {
  const api: any = useApi();
  const [auth, setAuth] = useState({
    authenticated: false,
    user: null,
    login: (data: FormData): Promise<any> => AuthenticationService.Login(api, data),
    logout: (): void => AuthenticationService.Logout(api),
    profile: (): Promise<any> => AuthenticationService.Profile(api)
  });
  useEffect(() => {
    AuthenticationService.currentUser.subscribe((user: any) => {
      setAuth((prev) => {
        const auth = user !== null;
        return {...prev, authenticated: auth, user };
      });
    });
  }, []);
  return (
    <AuthenticationContext.Provider value={auth}>{props.children}</AuthenticationContext.Provider>
  );
};

const useAuthentication = (): any => {
  return useContext(AuthenticationContext);
};

export {
  AuthenticationContextProvider,
  useAuthentication
};