import {prettyDate, timeToRead} from '_utils';
import React from 'react';
import {INVALID_DATE_STRING} from '_utils/Pretty';

const postedOn = (ts: string): string => {
  const pretty = prettyDate(ts);
  return pretty === INVALID_DATE_STRING ? '' : pretty;
};

const BlogEntryHeader = (props: { posted: string, wordCount?: number }): JSX.Element => {
  const ttr = timeToRead(props.wordCount);

  return (
    <div className='row-c blog-entry_header'>
      <div className='col sm-6 blog-entry_date'>{postedOn(props.posted)}</div>
      <div className='col sm-6 blog-entry_time-to-read'>Time to read: {ttr}</div>
    </div>
  )
};

export default BlogEntryHeader;