import React from 'react';

const CaseStudies = (): JSX.Element => {
  return (
    <div className='case-studies'>
      Case studies content is likely to be static.
    </div>
  );
};

export default CaseStudies;
