import {IMAGE_EXTENSIONS} from './@constants';

export const getExtension = (filename: string | undefined): string => {
  const split = filename?.split('.') || [];
  if (split.length > 1) {
    const ext = split.pop();
    if (ext) {
      return ext.toLowerCase();
    }
  }
  return '';
};

export const isImage = (filename: string | undefined): boolean => {
  const extension = getExtension(filename);
  return IMAGE_EXTENSIONS.indexOf(extension) > -1;
};

export const sameExtension = (a: string, b: string): boolean => {
  const extA = getExtension(a);
  const extB = getExtension(b);
  // No extension = no match.
  return extA && extB ? extA === extB : false;
};