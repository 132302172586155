import {BlogEntryStatus, BlogPosts, BlogPostsResponse} from '_sections/blog/@types';
import {Link} from 'react-router-dom';
import {prettyDate, prettyEnum} from '_utils';
import React, {useEffect, useMemo, useState} from 'react';
import {useApi} from '_service';
import {useEndpoint} from '_hooks';

const ListBlogPosts = (): JSX.Element | null => {
  const api = useApi();
  const [entries, setEntries] = useState<BlogPosts>();
  const [entriesCount, setEntriesCount] = useState(0);
  const [page, setPage] = useState(0);
  const options = useMemo(() => ({ blog: true, page }), [page]);
  const [getAllEntries] = useEndpoint<BlogPostsResponse>(api.getAllBlogEntries, options);

  useEffect(() => {
    if (getAllEntries) {
      setEntries(prev => {
        if (prev !== undefined) {
          const arr = [...prev, ...getAllEntries.content];
          return Array.from(new Set(arr));
        }
        return getAllEntries.content;
      });
      setEntriesCount(getAllEntries.totalElements);
    }
  }, [getAllEntries, setEntries, setEntriesCount]);

  const loadNextPage = (): void => {
    setPage((prev: number) => {
      return prev + 1;  
    });
  };

  if (!entries) {
    return null;
  }

  return (
    <>
      <div className='button-bar clearfix'>
        <Link to='/admin'>&larr; Back to admin</Link>
        <Link to='blog/new' className='new-post right'>New post</Link>
      </div>
      <div className='blog-admin'>
        <div className='post-listing'>
          <div className='row collapsed listing-header'>
            <div className='col sm-6'>Title</div>
            <div className='col sm-3'>Created</div>
            <div className='col sm-3 status'>Status</div>
          </div>
          {entries.map(entry => (
            <Link to={`blog/${entry.id}/edit`} key={entry.id}>
              <div className='row collapsed'>
                <div className='col sm-6 title'>{entry.title}</div>
                <div className='col sm-3'>{prettyDate(entry.ts)}</div>
                <div className='col sm-3 status'>{prettyEnum(entry.status, BlogEntryStatus)}</div>
              </div>
            </Link>
          ))}
          {entriesCount === 0 &&
            <div className='row collapsed'>
              <div className='col sm-12'>No entries found</div>
            </div>
          }
          {entriesCount > entries.length &&
            <div className='row collapsed'>
              <div className='col sm-12'>
                <Link to='#' onClick={loadNextPage}>Load more...</Link>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ListBlogPosts;
