import {randomRotation} from '_utils';
import React from 'react';

const Tape = (props: { maxRotation: number }): JSX.Element => {
  const rotation = randomRotation(props.maxRotation);
  return (
    <div className={`tape rot-${rotation}`} />
  );
};

export default Tape;