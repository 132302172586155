import { AdminLink, Login } from 'admin';
import { EditBlogPost, ListBlogPosts } from 'admin/blog';
import { ListFiles } from 'admin/files';
import React from 'react';
import { Route } from 'react-router-dom';
import { useAuthentication } from '_service';

const Admin = (): JSX.Element => {
  const {authenticated, logout} = useAuthentication();

  return (
    authenticated ? (
      <div className='admin'>
        <Route exact path='/admin'>
          <div className='menu clearfix'>
            <AdminLink label='Edit blog' stickyLabel='Fun times'
                       to='/admin/blog' />
            <AdminLink label='Manage files and images' stickyLabel='No selfie sticks'
                       className='blue' to='/admin/files' />
            <AdminLink label='Logout' stickyLabel='Run away!' onClick={logout}
                       className='red' to='#' />
          </div>
        </Route>
        <Route exact path='/admin/blog' component={ ListBlogPosts } />
        <Route exact path='/admin/blog/new' component={ EditBlogPost } />
        <Route path='/admin/blog/:id/edit' component={ EditBlogPost } />
        <Route exact path='/admin/files' component={ ListFiles } />
      </div>
    ) : (
      <Login />
    )
  );
}

export default Admin;
