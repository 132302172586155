import { BlogEntry } from '_sections/blog/entries';
import React from 'react';
import { Route } from 'react-router-dom';
import { Whiteboard } from '_sections/blog/whiteboard';

const Blog = (props: { match: any, history: any }): JSX.Element => {
  return (
    <div className='blog clearfix'>
      <Route exact path='/blog' component={ Whiteboard } />
      <Route path='/blog/:id'>
        <BlogEntry match={props.match} history={props.history} />
      </Route>
    </div>
  );
};

export default Blog;
