import {BlogEntryStatus} from '_sections/blog/@types';
import classNames from 'classnames';
import {Form} from '_components';
import {FormComponentOptions, FormState} from '_components/@types';
import {navigate} from '_utils';
import React, {FormEvent, useEffect, useMemo, useState} from 'react';
import {useApi} from '_service';
import {useEndpoint} from '_hooks';

const RECENT_SUCCESS = 5000;
const FORM_COMPONENTS: Array<FormComponentOptions> = [
  { id: 'title', label: 'Title', type: 'text', placeholder: 'Choose a meaningful title...', required: true },
  { id: 'thumbnail', label: 'Polaroid thumbnail', type: 'text', placeholder: 'Enter the name of an uploaded image', required: false },
  { id: 'body', label: 'Content', type: 'md', required: true, rows: 10 },
  { id: 'status', label: 'Status', type: 'select', required: false, options: BlogEntryStatus }
];

const EditBlogPost = (props: { history: any, match: any }): JSX.Element => {
  const api: any = useApi();
  let id = NaN;
  if (props) {
    const params = props.match.params;
    id = parseInt(params.id);
  }
  const title = `${isNaN(id) ? 'New' : 'Edit'} blog post`;

  const [formValid, setFormValid] = useState(false);
  const [saveError, setSaveError] = useState();
  const [saveSuccess, setSaveSuccess] = useState(false);
  const formChange = (state: FormState): void => {
    setFormValid(state.valid);
    setSaveError(undefined);
    setSaveSuccess(false);
  };

  const formSubmit = async (event: FormEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();
    if (formValid) {
      const formData = new FormData(event.currentTarget);
      const data = Object.fromEntries(formData);
      let result;
      if (isNaN(id)) {
        result = api.createBlogEntry(data);
      } else {
        result = api.updateBlogEntry(id, data);
      }
      result.then((value: any) => {
        setSaveSuccess(true);
        setSaveError(undefined);
        if (isNaN(id)) {
          navigate(props.history, `/admin/blog/${value.id}/edit`);
        }
      }).catch((reason: any) => {
        setSaveSuccess(false);
        setSaveError(reason.message);
      });
    }
    return null;
  };

  const formCancel = (): void => {
    props.history.goBack();
  };

  const options = useMemo(() => ({ blog: true, id: id }), [id]);
  const [loadedContent] = useEndpoint(api.getBlogEntry, options);
  const [loadedForm, setLoadedForm] = useState();
  useEffect(() => {
    if (!isNaN(id)) {
      setLoadedForm(loadedContent);
      if (loadedContent) {
        const ts = new Date(loadedContent.ts);
        if (Date.now() - ts.getTime() < RECENT_SUCCESS) {
          setTimeout(() => {
            setSaveSuccess(true);
          }, 50);
        }
      }
    }
  }, [loadedContent, id]);

  return (
    <div className={classNames('blog-post', {
      'save-failed': saveError,
      'save-succeeded': saveSuccess
    })}>
      <Form setValue={loadedForm} options={{
        title,
        components: FORM_COMPONENTS,
        onChange: formChange,
        submitLabel: 'Save',
        onSubmit: formSubmit,
        cancelLabel: 'Cancel',
        onCancel: formCancel
      }} disableSubmit={saveSuccess}>
        <>
          {saveError && (
            <div className='save-error'>
              Save failed: {saveError}
            </div>
          )}
          {saveSuccess && (
            <div className='save-success'>
              Saved successfully!
            </div>
          )}
        </>
      </Form>
    </div>
  );
};

export default EditBlogPost;
