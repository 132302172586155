import { BlogEntryContent } from '_sections/blog/@types';
import { randomRotation, timeToRead } from '_utils';
import React from 'react';

const PostIt = (props: { content: BlogEntryContent }): JSX.Element => {
  const content = props.content;
  const ttr = timeToRead(content.wordCount);
  const rotation = randomRotation(7);
  return (
    <div className={`post-it-wrapper rot-${rotation}`}>
      <div className='post-it'>
        <div className='post-it_sticky' title={`Time to read: ${ttr}`}>{ttr} read</div>
        {content.title}
      </div>
    </div>
  );
};

export default PostIt;
