export enum BlogEntryStatus {
  draft = 'Draft',
  published = 'Published'
}

export type BlogEntryContent = {
  id: number,
  title: string,
  body: string,
  status: BlogEntryStatus,
  thumbnail?: string,
  wordCount?: number,
  ts: string,
  author?: string
};

export type BlogPosts = Array<BlogEntryContent>;

export type BlogPostsResponse = {
  content: BlogPosts,
  totalElements: number
};

export type File = {
  name: string
};

export type PostItProps = {
  content: BlogEntryContent,
  onClick: (id: number) => void,
  selected?: boolean
}