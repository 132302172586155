import React from 'react';

const LoginError = (): JSX.Element => {
  return (
    <div className='login-error'>
      Incorrect username or password
    </div>
  );
};

export default LoginError;