import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {Polaroid, PostIt} from '_sections/blog/whiteboard';
import {PostItProps} from '_sections/blog/@types';
import React from 'react';

const WhiteboardEntry = (props: PostItProps): JSX.Element => {
  const content = props.content;
  const onClick = props.onClick.bind(null, content.id);
  return (
    <Link to='#' onClick={onClick} className={classNames('blog-entry_wrapper', {
      selected: props.selected === true,
      deselected: props.selected === false
    })}>
      { content.thumbnail ?
        <Polaroid content={content} />
        :
        <PostIt content={content} />
      }
    </Link>
  );
};

export default WhiteboardEntry;
