import React from 'react';

const Testimonials = (): JSX.Element => {
  return (
    <div className='testimonials'>
      Testimonials content is likely to be static.
    </div>
  );
};

export default Testimonials;
