import {History} from './@types';
import {NavigationRoute} from 'navigation/@types';
import {SITE_TITLE, TITLE_SEPARATOR, SHOW_BURGER_MENU_AT_OR_BELOW} from '_utils/@constants';

export const route = (navigationRoute: { route: string, params?: string }): string => {
  if (navigationRoute.params) {
    return `${navigationRoute.route}${navigationRoute.params}`;
  }
  return navigationRoute.route;
};

export const pageTitle = (routes: NavigationRoute[], route: string, suffix?: string): string => {
  const parts = [SITE_TITLE];
  const matches = routes.filter(r => route.startsWith(r.route));
  if (matches.length === 1 && matches[0].label) {
    parts.push(matches[0].label);
  }
  if (suffix) {
    parts.push(suffix);
  }
  return parts.join(` ${TITLE_SEPARATOR} `);
};

export const showBurgerMenu = (width: number): boolean => {
  return SHOW_BURGER_MENU_AT_OR_BELOW >= width;
};

export const navigate = (history: History, route: string, delay = 0): NodeJS.Timeout | void => {
  if (delay > 0) {
    return setTimeout(() => {
      history.push(route);
    }, delay);
  }
  history.push(route);
};