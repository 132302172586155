import React from 'react';
import ReactMarkdown from 'react-markdown';

const BlogEntryBody = (props: { body: string }): JSX.Element => {
  return (
    <div className='row-c blog-entry_body'>
      <div className='col sm-12 markdown'>
        <ReactMarkdown source={props.body} escapeHtml={false} />
      </div>
    </div>
  );
};

export default BlogEntryBody;