import classNames from 'classnames';
import { NavigationItem } from 'navigation';
import { NavigationRoute } from 'navigation/@types';
import { pageTitle } from '_utils';
import React, { useEffect } from 'react';
import { useAuthentication } from '_service';
import { useLocation } from 'react-router-dom';

const Navigation = (props: { routes: NavigationRoute[], expanded: boolean }): JSX.Element => {
  const { authenticated } = useAuthentication();
  const routes = props.routes;
  const { pathname } = useLocation();
  useEffect(() => {
    document.title = pageTitle(routes, pathname);
  }, [routes, pathname]);

  return (
    <nav className={classNames('navigation', { expanded: props.expanded })}>
      <ul>
        { routes &&
          routes.map(r => (
            (r.secure !== true || authenticated) &&
            <NavigationItem route={ r.route } label={ r.label } key={ r.route } />
          ))
        }
      </ul>
    </nav>
  );
};

export default Navigation;
