import {ApiService} from '_service';
import React, { createContext, useContext, useMemo } from 'react';

const ServiceContext = createContext({});

const ServiceContextProvider = (props: { children: any }): JSX.Element => {
  const api = useMemo(() => ApiService(), []);
  return (
    <ServiceContext.Provider value={api}>{props.children}</ServiceContext.Provider>
  );
};

const useApi = (): any => {
  return useContext(ServiceContext);
};

export {
  ServiceContextProvider,
  useApi
};