import Authentication from '_service/auth';
import Blog from '_service/blog';
import GraphQL from '_service/core';
import Files from '_service/files';

export default function ApiService(): any {

  return {
    apolloClient: GraphQL.apolloClient,
    ...Authentication,
    ...Blog,
    ...Files
  };
}
