import {BehaviorSubject} from 'rxjs';
import {handleResponse} from '_utils';

type ProfileAPI = { getProfile: () => Promise<Response> };
type LogoutAPI = { logout: () => Promise<Response> };
type LoginAPI = ProfileAPI & { login: (options: { data: FormData }) => Promise<Response> };

const CURRENT_USER_KEY = 'CURRENT_USER';
const currentUserSubject = new BehaviorSubject<any>(getCurrentUserFromStorage());

function getCurrentUserFromStorage(): string | null {
  const currentUser = localStorage.getItem(CURRENT_USER_KEY);
  if (currentUser) {
    return JSON.parse(currentUser);
  }
  return null;
}
async function Login(api: LoginAPI, data: FormData): Promise<any> {
  return api.login({ data })
    .then(handleResponse)
    .then(async () => {
      // We've been given the roles, now get hold of the user profile.
      const profile = await Profile(api);

      // Store the user profile in local storage to keep the user logged
      // in between page refreshes.
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(profile));
      currentUserSubject.next(profile);

      return profile;
    })
    .catch((error: any) => {
      return { error };
    });
}
async function Profile(api: ProfileAPI): Promise<any> {
  return api.getProfile()
    .then(handleResponse)
    .then((profile: any) => {
      return profile;
    });
}

function Logout(api: LogoutAPI): void {
  api.logout()
    .then(handleResponse)
    .then(() => {
      // Remove the user from local storage to log user out
      localStorage.removeItem(CURRENT_USER_KEY);
      currentUserSubject.next(null);
      return null;
    });
}


const AuthenticationService = {
  Login,
  Logout,
  Profile,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue(): any { return currentUserSubject.value }
};

export default AuthenticationService;
