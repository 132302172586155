import {Link} from 'react-router-dom';
import React, {useRef, useEffect} from 'react';

type UploadFileProperties = {
  label: string,
  onFileSelected: (data: FormData) => void,
  className?: string,
  simulateClick?: number
}

const UploadFile = (props: UploadFileProperties): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChange = (): void => {
    if (formRef.current) {
      const data = new FormData(formRef.current);
      props.onFileSelected(data);
    }
  };

  // Simulate clicking the input by "clicking" the link.
  const clickInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  useEffect(() => {
    if (props.simulateClick) {
      clickInput();
    }
  }, [props.simulateClick]);

  return (
    <form ref={formRef} className={`upload-file clearfix ${props.className}`}>
      <Link to='#' onClick={clickInput}>{props.label}</Link>
      <input ref={inputRef} type='file' name='file' onChange={handleChange} />
    </form>
  );
};

export default UploadFile;
