import React from 'react';

const Services = (): JSX.Element => {
  return (
    <div className='services'>
      Services content is likely to be static.
    </div>
  );
};

export default Services;
