import {NavigationRoute} from 'navigation/@types';
import {NavLink} from 'react-router-dom';
import React from 'react';

const NavigationItem = (props: NavigationRoute): JSX.Element => {
  return (
    <li>
      <NavLink to={props.route}>{props.label}</NavLink>
    </li>
  )
};

export default NavigationItem;
