import HomeLink from './HomeLink';
import { Navigation } from 'navigation';
import { NavigationRoute } from 'navigation/@types';
import React, { useEffect, useState, useRef } from 'react';
import { showBurgerMenu } from '_utils/Navigation';
import { useDebounce } from '_hooks';
import { useLocation } from 'react-router-dom';
import useOnClickOutside from '_service/useOnClickOutside';

const Header = (props: { routes: NavigationRoute[] }): JSX.Element => {
  // Get hold of a reference to the <header /> so we can detect
  // clicking elsewhere.
  const ref = useRef(null);

  // Whether or not the (burger-driven) menu is in an expanded state.
  // i.e., whether or not the actual menu items are visible.
  const [expanded, setExpanded] = useState(false);

  // Reusable function for setting expanded to false.
  const collapse = () => {
    setExpanded(false);
  };

  // Watch for changes to the path so we can collapse the menu when
  // the user clicks on one of the items.
  const { pathname } = useLocation();
  useEffect(() => {
    collapse();
  },[pathname]);

  // Debounce the resize function so it only fires ever 50ms, which will
  // improve the performance when resizing the screen.
  const resize = useDebounce(() => {
    if (expanded && !showBurgerMenu(window.innerWidth)) {
      collapse();
    }
  }, 50);

  // Listen out for changes to the screen size. When the screen is above the size
  // at which the burger menu is shown, simply collapse the menu.
  // NOTE: This is so that it doesn't immediately show again if the screen size is
  // reduced once more - e.g., when changing the orientation on a mobile device.
  useEffect(() => {
    if (expanded) {
      const internalResize = resize;
      window.addEventListener('resize', internalResize, true);
      return () => {
        // Remove the event listener whenever we're not expanded.
        window.removeEventListener('resize', internalResize, true);
      };
    }
  }, [expanded, resize]);

  // Show or hide the menu. This is only invoked by clicking on the burger icon.
  function toggleMenu() {
    setExpanded(prevState => {
      return !prevState;
    });
  }

  // Handle clicking outside of the <header /> element.
  useOnClickOutside(ref, collapse);

  return (
    <header ref={ref}>
      <div className='header-content unselectable'>
        <HomeLink />
        <div className='nav-icon' aria-describedby='Show menu' onClick={toggleMenu}><span/></div>
        <Navigation routes={props.routes} expanded={expanded} />
      </div>
    </header>
  );
};

export default Header;
