import BlackMarker from 'assets/img/black-marker.png';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

const Footer = (): JSX.Element => {
  const [showMarker, setShowMarker] = useState('');
  const { pathname } = useLocation();
  useEffect(() => {
    const show = pathname === '/blog' ? 'show' : '';
    setShowMarker(show);
  }, [pathname]);

  return (
    <footer>
      <div className='footer-content'>&copy; RadicalIT 2020</div>
      <Link to='/admin' className='admin-link'>
        <img className={`marker ${showMarker}`} src={BlackMarker} alt='Whiteboard marker' />
      </Link>
    </footer>
  );
};

export default Footer;
