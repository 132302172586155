import {Admin} from 'admin';
import {AboutUs, Blog, CaseStudies, Services, Testimonials} from '_sections';
import {NavigationRoute} from 'navigation/@types';

/**
 * Don't include the "Home" route here as it shouldn't appear in the navigation
 * section - it's the default and accessible by clicking on the logo.
 */
const Routes: NavigationRoute[] = [
  { route: '/about-us',     label: 'About us',     component: AboutUs },
  { route: '/services',     label: 'Services',     component: Services },
  { route: '/case-studies', label: 'Case studies', component: CaseStudies },
  { route: '/testimonials', label: 'Testimonials', component: Testimonials },
  { route: '/blog',         label: 'Blog',         component: Blog,  params: '/:id?' },
  { route: '/admin',        label: 'Admin',        component: Admin, secure: true }
];

export default Routes;
