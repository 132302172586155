import {Link} from 'react-router-dom';
import React from 'react';

type AdminLinkOptions = {
  label: string,
  stickyLabel: string,
  to: string,
  className?: string,
  onClick?: () => void
}
const AdminLink = (options: AdminLinkOptions): JSX.Element => {
  const onClick = (): void => {
    if (typeof options.onClick === 'function') {
      options.onClick();
    }
  }
  const rotation = Math.floor(Math.random() * 14) - 7;

  return (
    <Link to={options.to} onClick={onClick}>
      <div className={`post-it-wrapper ${options.className} rot-${rotation}`}>
        <div className='post-it'>
          <div className='post-it_sticky'>{options.stickyLabel}</div>
          {options.label}
        </div>
      </div>
    </Link>
  )
};

export default AdminLink;
