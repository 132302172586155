import {AVERAGE_WORDS_PER_MINUTE} from './@constants';

export const wordCount = (str: string | undefined): number => {
  if (str) {
    return str.split(/\s/).filter((n) => n !== '').length;
  }
  return 0;
};

export const timeToRead = (wordCount: number | undefined): string => {
  const minutes = wordCount === undefined ? 0 : wordCount / AVERAGE_WORDS_PER_MINUTE;
  if (minutes < 1) {
    return '< 1 min';
  }
  return `about ${minutes.toFixed(0)} min`;
};
