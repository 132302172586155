import {INVALID_DATE_STRING} from '_utils/Pretty';
import {prettyTime} from '_utils';
import React from 'react';

const UNKNOWN_AUTHOR = '< unknown_user >';
const author = (name?: string): string => {
  return name || UNKNOWN_AUTHOR;
};

const postedAt = (ts: string): string => {
  const pretty = prettyTime(ts);
  if (pretty === INVALID_DATE_STRING) {
    return 'Posted';
  }
  return `Posted at ${pretty}`;
};

const BlogEntryFooter = (props: { posted: string, author?: string }): JSX.Element => {
  return (
    <div className='row-c blog-entry_footer'>
      <div className='col sm-12 blog-entry_posted'>
        {postedAt(props.posted)} by {author(props.author)}
      </div>
    </div>
  );
};

export default BlogEntryFooter;