import {useEffect} from 'react';

type MouseOrTouchEvent = MouseEvent | TouchEvent;
type handlerType = (event: MouseOrTouchEvent) => void;
const useOnClickOutside = (ref: React.MutableRefObject<HTMLElement | null>, handler: handlerType): void => {
  useEffect(
    () => {
      const listener = (event: MouseOrTouchEvent) => {
        const target = event.target;
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || (target instanceof Node && ref.current.contains(target))) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },[ref, handler]);
};

export default useOnClickOutside;