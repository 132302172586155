import {File} from '_sections/blog/@types';
import FileThumb from './FileThumb';
import {Link} from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import {sameExtension, handleResponse} from '_utils';
import {UploadFile} from 'admin/files/index';
import {useApi} from '_service';
import {useEndpoint} from '_hooks';

function handleInvalidExtension(originalName: string, newName: string): void {
  alert(`Cannot use '${newName}' as a replacement for '${originalName}' as it has a different extension.`);
}

type Files = Array<File>;
type FilesResponse = {
  content: Files,
  totalElements: number
};

const ListFiles = (): JSX.Element | null => {
  const api = useApi();
  const [lastUpload, setLastUpload] = useState(Date.now());
  const options = useMemo(() => ({ files: true, lastUpload }), [lastUpload]);
  const [getAllFiles] = useEndpoint<FilesResponse>(api.getAllFiles, options);
  const [files, setFiles] = useState<Files>();
  const [filesCount, setFilesCount] = useState(0);
  const [replacing, setReplacing] = useState<File>();
  const [simulateClick, setSimulateClick] = useState<number>();
  const [replacedAt, setReplacedAt] = useState<number>(0);

  useEffect(() => {
    if (getAllFiles) {
      setFiles(getAllFiles.content);
      setFilesCount(getAllFiles.totalElements);
    }
  }, [getAllFiles, setFiles, setFilesCount]);

  const handleNewFile = (data: FormData): void => {
    const file = data.get('file') as globalThis.File;
    if (file && file.name) {
      let fn = api.upload;
      if (replacing) {
        fn = api.replace;
        // Make sure the name is the same as the one we're replacing.
        if (file.name !== replacing.name) {
          // But before we even do that, make sure the extensions are the same.
          if (!sameExtension(file.name, replacing.name)) {
            return handleInvalidExtension(replacing.name, file.name);
          }
          data.delete('file');
          data.append('file', file, replacing.name);
        }
        setReplacing(undefined);
      }
      return fn({ data })
        .then(handleResponse)
        .then(() => {
          setLastUpload(Date.now());
          setReplacedAt(Date.now());
        })
        .catch((error: any) => {
          return { error };
        });
    }
  };

  const handleReplaceFile = (file: File): void => {
    setReplacing(file);
    setSimulateClick(Date.now());
  };

  if (!files) {
    return null;
  }
  return (
    <>
      <div className='button-bar clearfix'>
        <Link to='/admin'>&larr; Back to admin</Link>
        <UploadFile label='Upload new' className='right' onFileSelected={handleNewFile} simulateClick={simulateClick} />
      </div>
      <div className='files-admin'>
        <div className='files-listing'>
          <div className='row collapsed listing-header'>
            <div className='col sm-12'>Existing files</div>
          </div>
          <div className='listing-content clearfix'>
          {files.map(file => (
            <FileThumb file={file} key={file.name} onReplace={handleReplaceFile} replacedAt={replacedAt} />
          ))}
          </div>
          {filesCount === 0 &&
            <div className='row collapsed'>
              <div className='col sm-12'>No files found</div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ListFiles;
