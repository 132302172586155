import {FormComponent} from '_components';
import {FormComponentOptions, FormComponentState, FormOptions, FormProperties, FormState} from '_components/@types';
import React, {useEffect, useState} from 'react';

function getInitialStateFromOptions(options: FormOptions): any {
  return options.components.map((component: FormComponentOptions) => {
    return component.id;
  }).reduce((previous: any, current: string) => {
    previous[current] = null;
    return previous;
  }, {});
}

const Form = (props: FormProperties): JSX.Element => {
  const options = props.options;
  const initialState: FormState = { options, valid: false };
  const [formState, setFormState] = useState(initialState);
  const initialComponentStates: any = getInitialStateFromOptions(options);
  const [states, setStates] = useState(initialComponentStates);

  const componentStateChange = (state: FormComponentState): void => {
    const id = state.options.id;
    setStates((prevState: any) => {
      return { ...prevState, [id]: state };
    });
  };

  // Handle the states changing.
  useEffect(() => {
    const valid = Object.entries(states).map(([key, value]) => {
      const state = (value as unknown as FormComponentState);
      if (state !== null) {
        return state.valid;
      }
      const component = options.components.find(c => c.id === key);
      if (component) {
        return !component.required;
      }
      return false;
    }).reduce((prev, current) => {
      return prev && current;
    }, true);
    setFormState((prevState) => {
      return { ...prevState, valid };
    });
  }, [states, options.components]);

  // Handle the formState changing.
  useEffect(() => {
    options.onChange(formState);
    // eslint-disable-next-line
  }, [formState]);

  return (
    <form className='form' onSubmit={options.onSubmit}>
      <div className='form-title'>{options.title}</div>
      {props.children}
      {options.components.map(c =>
        <FormComponent options={{ ...c, onChange: componentStateChange }} key={c.id}
                       setValue={props.setValue ? props.setValue[c.id] : undefined} />
      )}
      <div className='form_buttons'>
        {
          options.onCancel &&
          <button className='secondary' type='button' onClick={options.onCancel}>
            {options.cancelLabel || 'Cancel'}
          </button>
        }
        <button className='primary' type='submit' disabled={!formState.valid || props.disableSubmit}>
          {options.submitLabel}
        </button>
      </div>
    </form>
  );
};

export default Form;
