import { BlogEntryContent } from '_sections/blog/@types';
import { BlogEntryBody, BlogEntryFooter, BlogEntryHeader, BlogEntryTitle } from '_sections/blog/entries';
import { useApi, useAuthentication } from '_service';
import React, { useMemo } from 'react';
import { useEndpoint } from '_hooks';

const BlogEntry = (props: { match: any, history: any }): JSX.Element | null => {
  const api: any = useApi();
  const { authenticated } = useAuthentication();

  const id = parseInt(props.match.params.id);
  const options = useMemo(() => ({ id }), [id]);
  const [content] = useEndpoint<BlogEntryContent>(api.getBlogEntry, options);

  if (!content) {
    return null;
  }

  return (
    <div className='blog-entry'>
      <BlogEntryTitle title={content.title} id={content.id} authenticated={authenticated} />
      <BlogEntryHeader posted={content.ts} wordCount={content.wordCount} />
      <BlogEntryBody body={content.body} />
      <BlogEntryFooter posted={content.ts} author={content.author} />
    </div>
  );
};

export default BlogEntry;
