import { BlogEntryContent } from '_sections/blog/@types';
import { randomRotation, timeToRead } from '_utils';
import React from 'react';
import Tape from './Tape';

const Polaroid = (props: { content: BlogEntryContent }): JSX.Element => {
  const content = props.content;
  const ttr = timeToRead(content.wordCount);
  const rotation = randomRotation(7);
  return (
    <div className={`polaroid-wrapper rot-${rotation}`}>
      <div className='polaroid'>
        <div className='polaroid_ttr' title={`Time to read: ${ttr}`}>{ttr}</div>
        <img src={content.thumbnail} alt='Post thumbnail' />
        <div className='polaroid_caption'>{content.title}</div>
      </div>
      <Tape maxRotation={30} />
    </div>
  );
};

export default Polaroid;
