import {DATE_FORMAT, TIME_FORMAT} from './@constants';
import {EnumType} from './@types';
import moment from 'moment';

export const INVALID_DATE_STRING = moment.localeData().invalidDate();

export const prettyDate = (ts: string): string => {
  return moment(ts, moment.ISO_8601).format(DATE_FORMAT);
};

export const prettyTime = (ts: string): string => {
  return moment(ts, moment.ISO_8601).format(TIME_FORMAT);
};

export const prettyEnum = (value: string, t: EnumType): string => {
  return t[value];
};

export const randomRotation = (magnitude: number): number => {
  const m = Math.abs(magnitude);
  if (m > 0) {
    return Math.floor(Math.random() * m * 2) - m;
  }
  return 0;
};