import classNames from 'classnames';
import { Form } from '_components';
import { FormComponentOptions, FormOptions, FormState } from '_components/@types';
import { LoginError } from 'admin';
import React, { FormEvent, useState } from 'react';
import { useAuthentication } from '_service';

const FORM_COMPONENTS: Array<FormComponentOptions> = [
  { id: 'username', label: 'Username', type: 'text', required: true },
  { id: 'password', label: 'Password', type: 'password', required: true }
];

const Login = (): JSX.Element => {
  const { login } = useAuthentication();

  const [loginFailed, setLoginFailed] = useState(false);
  const [formValid, setFormValid] = useState(false);

  // Handles submitting the form.
  const onSubmit = async (event: FormEvent<HTMLFormElement>): Promise<any> => {
    event.preventDefault();
    if (formValid) {
      const { error } = await login(new FormData(event.currentTarget));
      if (error) {
        setLoginFailed(true);
      }
    }
  }

  const onChange = (state: FormState): void => {
    setFormValid(state.valid);
    setLoginFailed(false);
  };

  const options: FormOptions = {
    title: 'Login',
    components: FORM_COMPONENTS,
    onChange,
    submitLabel: 'Login',
    onSubmit
  };

  return (
    <div className={classNames('login', {'login-failed': loginFailed})}>
      <Form options={options} disableSubmit={loginFailed}>
        {loginFailed && <LoginError />}
      </Form>
    </div>
  );
};

export default Login;
